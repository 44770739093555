<script>
import { logout } from '@/apis/auth'
import store from '@/state/store'
/**
 * Topbar component
 */
export default {
  data() {
    return {
      languageList:[
        {text:"简体中文",icon:"china_flag.jpg",code:"zh-CN"},
        {text:"English",icon:"us_flag.jpg",code:"en"}
      ]
    };
  },
  computed:{
    userInfo:function(){
      if(store.state.auth.currentUser){
        return store.state.auth.currentUser
      }else{
        return {nickName:''}
      }
    },
    curLanguageIconURL:function(){
      return require('@/assets/images/flags/'+this.curLanguage.icon);
    },
    curLanguage:function(){
      if(this.$i18n.locale=="zh-CN"){
        return {text:"简体中文",icon:"china_flag.jpg"}
      }else{
        return {text:"English",icon:"us_flag.jpg"}
      }
    }
  },
  created(){
    if(store.state.auth.currentUser==null){
      store.dispatch('auth/refreshUserInfo')
    }
  },
  methods: {
    languageIconURL(icon){
      return require('@/assets/images/flags/'+icon);
    },
    changeLanguage(code){
      this.$i18n.locale = code
      localStorage.setItem('language',code)
    },
    
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    handleLogout(){
      logout().then(()=>{
        store.dispatch('auth/logOut')
        this.$router.push({
          name: 'login',
          query: {
            redirectFrom: this.$router.currentRoute.fullPath
          }
        });
      })
    }
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.svg" alt height="36" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.svg" alt height="36" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>

        <div class="d-none d-sm-block" style="line-height: 70px;">Welcome {{ userInfo.nickName }}
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-none d-md-block ms-2"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-earth"></i>
            {{curLanguage.text}}
            <span class="mdi mdi-chevron-down"></span>
          </template>
          <a href="javascript:void(0);" @click="changeLanguage(item.code)" class="dropdown-item notify-item" v-for="item in languageList" :key="item.code">
            <span class="align-middle">{{item.text}}</span>
          </a>          
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div>
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-bell-outline"></i>
            <span class="badge bg-danger rounded-pill">1</span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">Notifications (2)</h5>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px;">
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-success rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">公司通知</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      近期将企业OA系统.
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span
                      class="avatar-title bg-warning rounded-circle font-size-16"
                    >
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">系统通知</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">网盘模块已经更新上线</p>
                  </div>
                </div>
              </div>
            </a>

          </div>
          <div class="p-2 border-top">
            <div class="d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 text-center"
                href="javascript:void(0)"
              >
                查看全部
              </a>
            </div>
          </div>
        </b-dropdown>

        <b-dropdown
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/user-4.jpg"
              alt="Header Avatar"
            />
          </template>
          <b-dropdown-item href="/profile">
            <i class="mdi mdi-account-circle font-size-17 align-middle me-1"></i>
            我的档案
          </b-dropdown-item>
          <!-- <b-dropdown-item>
            <i class="mdi mdi-wallet font-size-17 align-middle me-1"></i> 我的报销
          </b-dropdown-item>
          <b-dropdown-item>
            <i
              class="mdi mdi-lock-open-outline font-size-17 align-middle me-1"
            ></i>
            锁屏
          </b-dropdown-item> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" style="cursor:pointer;" @click="handleLogout">
            <i
              class="bx bx-power-off font-size-17 align-middle me-1 text-danger"
            ></i>
            注销
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block" v-if="false">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="mdi mdi-cog-outline toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
