<script>
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";
import { layoutMethods } from "@/state/helpers";
import {menuTree} from '@/apis/user'

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      menuItems:[],
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  components: {
    simplebar,
  },
  created:function(){
    this.initMenu();
  },
  mounted: function() {
  },
  updated(){
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
  },
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined && item.subItems !== null ? item.subItems.length > 0 : false;
    },
    async initMenu(){
      await menuTree().then((res)=>{
        this.menuItems = res.data
      })
    },
    activeMenu(item){
      if(item.link==window.location.pathname){
        return true
      }else{
        if(item.subItems!=null && item.subItems.length>0){
          let childActive = false;
          for(let i=0;i<item.subItems.length;i++){
            if(this.activeMenu(item.subItems[i])){
              childActive = true
              break;
            }
          }
          return childActive
        }else{
          return false
        }        
      }
    },
    refreshMenu(){
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.setAttribute("data-topbar", "light");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-keep-enlarged");
              break;
            case "icon":
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <template v-for="item in menuItems">
            <li class="menu-title" v-if="item.isTitle" :key="item.id" >
              {{ $t(item.i18n) }}
            </li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :class="{'mm-active':activeMenu(item)}">
              <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent"
                :class="{
                  'has-arrow': !item.badge,
                  'has-dropdown': item.badge,
                }"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span> {{ $t(item.i18n) }}</span>
                <span
                  :class="
                    `badge rounded-pill bg-${item.badge.variant} float-end`
                  "
                  v-if="item.badge"
                  >{{ item.badge.text }}</span
                >
              </a>

              <router-link
                :to="item.link"
                v-if="!hasItems(item)"
                class="side-nav-link"
                :class="{'active':activeMenu(item)}"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span> {{ $t(item.i18n) }}</span>
                <span
                  :class="
                    `badge rounded-pill bg-${item.badge.variant} float-end`
                  "
                  v-if="item.badge"
                  >{{ item.badge.text }}</span
                >
              </router-link>

              <ul v-if="hasItems(item)" class="sub-menu mm-collapse" aria-expanded="false"
              :class="{'mm-show':activeMenu(item)}"
              >
                <li v-for="(subitem, index) of item.subItems" :key="index" :class="{'mm-active':activeMenu(subitem)}">
                  <router-link
                    :to="subitem.link"
                    v-if="!hasItems(subitem)"
                    class="side-nav-link"
                    :class="{'active':activeMenu(subitem)}"
                    ><i :class="`${subitem.icon}`" v-if="subitem.icon"></i>{{ $t(subitem.i18n) }}</router-link
                  >
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a has-arrow"
                    href="javascript:void(0);"
                    :class="{'active':activeMenu(subitem)}"
                    >{{ $t(subitem.i18n) }}</a
                  >
                  <ul
                    v-if="hasItems(subitem)"
                    class="sub-menu mm-collapse"
                    :class="{'mm-show':activeMenu(subitem)}"
                    aria-expanded="false"
                  >
                    <li
                      v-for="(subSubitem, index) of subitem.subItems"
                      :key="index"
                    >
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link"
                        :class="{'active':activeMenu(subSubitem)}"
                        >{{ $t(subSubitem.i18n) }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>
